import React, {ReactNode, useEffect, useState} from "react";
import {BrowserRouter as Router, Route, Routes, useLocation} from "react-router-dom";
import {Container} from "@mui/material";
import Header from "./components/Header";
import ImageGrid from "./components/ImageGrid";
import MondrianCanvas from "./pages/MondrianCanvas";
import MiroCanvas from "./pages/MiroCanvas";
import KandinskyCanvas from "./pages/KandinskyCanvas";

interface LayoutProps {
    children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({children}) => {
    const location = useLocation();

    // Prikaži Header samo na glavni strani
    const showHeader = location.pathname === "/";
    return (
        <Container maxWidth="md" sx={{paddingTop: 4}}>
            {showHeader && <Header/>}
            {children}
        </Container>
    );
};

type ScreenSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | undefined;

const useScreenSize = (): ScreenSize => {
    const [screenSize, setScreenSize] = useState<ScreenSize>(undefined);

    useEffect(() => {
        function getScreenSize(): ScreenSize {
            if (window.innerWidth < 640) return 'xs';
            if (window.innerWidth < 768) return 'sm';
            if (window.innerWidth < 1024) return 'md';
            if (window.innerWidth < 1280) return 'lg';
            if (window.innerWidth < 1536) return 'xl';
            return '2xl';
        }

        function handleResize() {
            setScreenSize(getScreenSize());
        }

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return screenSize;
};

const App: React.FC = () => {
    const isMobile = useScreenSize();
    let canvasSize = isMobile === "xs" || isMobile === "sm" ? 350 : 700

    return (
        <Router>
            <Layout>
                <Routes>
                    <Route path="/" element={<ImageGrid/>}/>

                    <Route path="/mondrian" element={
                        <MondrianCanvas canvasSize={canvasSize}/>
                    }/>

                    <Route path="/miro" element={
                        <MiroCanvas canvasSize={canvasSize}/>
                    }/>

                    <Route path="/kandinsky" element={
                        <KandinskyCanvas canvasSize={canvasSize}/>
                    }/>
                </Routes>
            </Layout>
        </Router>
    );
};

export default App;
