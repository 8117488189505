import React, { useEffect, useRef, useState } from "react";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";

interface CanvasProps {
    canvasSize: number
}

const MondrianCanvas: React.FC<CanvasProps> = ({ canvasSize }) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [canvasKey, setCanvasKey] = useState<number>(0);  // State to trigger re-render for new images

    const drawMondrian = (ctx: CanvasRenderingContext2D) => {
        const colors = ["#FF0000", "#0000FF", "#FFFF00", "#FFFFFF", "#000000"];
        const lineThickness = 10;
        const canvasWidth = ctx.canvas.width;
        const canvasHeight = ctx.canvas.height;

        // Belo ozadje
        ctx.fillStyle = "#FFFFFF";
        ctx.fillRect(0, 0, canvasWidth, canvasHeight);

        // 7 naključnih štirikotnikov
        for (let i = 0; i < 7; i++) {
            const x = Math.random() * canvasWidth;
            const y = Math.random() * canvasHeight;
            const width = Math.random() * (canvasWidth / 3);
            const height = Math.random() * (canvasHeight / 3);

            ctx.fillStyle = colors[Math.floor(Math.random() * colors.length)];
            ctx.fillRect(x, y, width, height);

            // Črni rob okoli štirikotnika
            ctx.lineWidth = lineThickness;
            ctx.strokeStyle = "#000000";
            ctx.strokeRect(x, y, width, height);
        }

        // Pravilo zlatega reza
        const goldenRatio = 0.618;
        const goldenX = [canvasWidth * goldenRatio, canvasWidth * (1 - goldenRatio)];
        const goldenY = [canvasHeight * goldenRatio, canvasHeight * (1 - goldenRatio)];

        const gridLines = [...goldenX, ...goldenY].sort((a, b) => a - b);

        // Mreža (3 črte vertikalno, 3 horizontalno)

        // for (let x of gridLines) {
        //     ctx.beginPath();
        //     ctx.moveTo(x, 0);
        //     ctx.lineTo(x, canvasHeight);
        //     ctx.lineWidth = lineThickness;
        //     ctx.strokeStyle = "#000000";
        //     ctx.stroke();
        // }
        //
        // for (let y of gridLines) {
        //     ctx.beginPath();
        //     ctx.moveTo(0, y);
        //     ctx.lineTo(canvasWidth, y);
        //     ctx.lineWidth = lineThickness;
        //     ctx.strokeStyle = "#000000";
        //     ctx.stroke();
        // }

        for (let i = 0; i < 3; i++) {
            const x = Math.random() * canvasWidth;
            const y = Math.random() * canvasHeight;

            // Vertikale
            ctx.beginPath();
            ctx.moveTo(x, 0);
            ctx.lineTo(x, canvasHeight);
            ctx.lineWidth = lineThickness;
            ctx.strokeStyle = "#000000";
            ctx.stroke();

            // Horizontale
            ctx.beginPath();
            ctx.moveTo(0, y);
            ctx.lineTo(canvasWidth, y);
            ctx.lineWidth = lineThickness;
            ctx.strokeStyle = "#000000";
            ctx.stroke();
        }
    };

    useEffect(() => {
        const canvas = canvasRef.current;
        if (canvas) {
            const ctx = canvas.getContext("2d");
            if (ctx) {
                drawMondrian(ctx);
            }
        }
    }, [canvasKey]);  // Re-run drawing logic when canvasKey changes

    const navigate = useNavigate();

    const generateImage = () => {
        // Povečam canvasKey za re-render
        setCanvasKey(prevKey => prevKey + 1);
    };

    const saveImage = () => {
        const canvas = canvasRef.current;
        if (canvas) {
            const dataUrl = canvas.toDataURL("image/jpg");
            const a = document.createElement("a");
            a.href = dataUrl;
            a.download = "mondrian-Rogoznica.jpg";
            a.click();
        }
    };

    return (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
            <h1>Piet Mondrian</h1>
            <canvas
                ref={canvasRef}
                width={canvasSize}
                height={canvasSize}
                style={{ border: "1px solid black" }}
            />

            <Stack spacing={2} direction="row" sx={{ mt: "20px" }} justifyContent="center">
                <Button variant="outlined" onClick={generateImage} sx={{ mr: 2 }}>
                    Naslikaj
                </Button>
                <Button variant="outlined" onClick={saveImage} sx={{ mr: 2 }}>
                    Shrani sliko
                </Button>
                <Button variant="outlined" onClick={() => { navigate("/") }} sx={{ mr: 2 }}>
                    Nazaj
                </Button>
            </Stack>

        </div>
    );
};

export default MondrianCanvas;
