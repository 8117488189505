import React, {useEffect, useRef, useState} from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";

type Shape = {
    x: number;
    y: number;
    type: string;
    color: string;
    size: number;
};

interface CanvasProps {
    canvasSize: number
}

const MiroCanvas: React.FC<CanvasProps> = ({canvasSize}) => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const [shapes, setShapes] = useState<Shape[]>([]);

    useEffect(() => {
        generateImage();
    }, []);

    useEffect(() => {
        drawCanvas();
    }, [shapes]);

    const generateImage = () => {
        const newShapes: Shape[] = [];
        const shapeCount = Math.floor(Math.random() * 10) + 5; // Naključno število oblik (5–15)
        const types = ["circle", "rectangle", "line", "zigzag", "spiral"];
        const colors = ["#ff0000", "#0000ff", "#ffff00", "#000000", "#00ff00"];

        for (let i = 0; i < shapeCount; i++) {
            const x = Math.random() * 800;
            const y = Math.random() * 600;
            const type = types[Math.floor(Math.random() * types.length)];
            const color = colors[Math.floor(Math.random() * colors.length)];
            const size = Math.random() * 50 + 10;

            newShapes.push({x, y, type, color, size});
        }

        setShapes(newShapes);
    };

    const drawCanvas = () => {
        const canvas = canvasRef.current;
        if (!canvas) return;
        const ctx = canvas.getContext("2d");
        if (!ctx) return;

        // Čiščenje canvas-a
        ctx.fillStyle = "#fff";
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        // Funkcije za risanje različnih oblik
        const drawCircle = (x: number, y: number, size: number, color: string) => {
            ctx.beginPath();
            ctx.arc(x, y, size, 0, Math.PI * 2);
            ctx.fillStyle = color;
            ctx.fill();
        };

        const drawRectangle = (x: number, y: number, size: number, color: string) => {
            ctx.fillStyle = color;
            ctx.fillRect(x - size / 2, y - size / 2, size, size);
        };

        const drawLine = (x: number, y: number, size: number) => {
            ctx.beginPath();
            ctx.moveTo(x - size, y - size);
            ctx.lineTo(x + size, y + size);
            ctx.strokeStyle = "#000000"; // Vedno črna
            ctx.lineWidth = 2;
            ctx.stroke();
        };

        const drawZigZag = (x: number, y: number, size: number) => {
            ctx.beginPath();
            const segment = size / 5;
            for (let i = 0; i < 5; i++) {
                const offset = i % 2 === 0 ? -segment : segment;
                ctx.lineTo(x + i * segment, y + offset);
            }
            ctx.strokeStyle = "#000000"; // Vedno črna
            ctx.lineWidth = 2;
            ctx.stroke();
        };

        const drawSpiral = (x: number, y: number, size: number) => {
            ctx.beginPath();
            let angle = 0;
            const increment = Math.PI / 10;
            for (let i = 0; i < size * 3; i++) {
                const radius = i / 5;
                const posX = x + radius * Math.cos(angle);
                const posY = y + radius * Math.sin(angle);
                ctx.lineTo(posX, posY);
                angle += increment;
            }
            ctx.strokeStyle = "#000000"; // Vedno črna
            ctx.lineWidth = 2;
            ctx.stroke();
        };

        const drawShapes = () => {
            shapes.forEach((shape) => {
                switch (shape.type) {
                    case "circle":
                        drawCircle(shape.x, shape.y, shape.size, shape.color);
                        break;
                    case "rectangle":
                        drawRectangle(shape.x, shape.y, shape.size, shape.color);
                        break;
                    case "line":
                        drawLine(shape.x, shape.y, shape.size);
                        break;
                    case "zigzag":
                        drawZigZag(shape.x, shape.y, shape.size);
                        break;
                    case "spiral":
                        drawSpiral(shape.x, shape.y, shape.size);
                        break;
                    default:
                        break;
                }
            });
        };

        const drawConnections = () => {
            if (shapes.length < 2) return;

            const connectionCount = Math.floor(shapes.length * 0.75); // 75 % povezav
            const usedPairs = new Set<string>();

            ctx.strokeStyle = "#000000"; // Črna za povezovalne črte
            ctx.lineWidth = 1;

            for (let i = 0; i < connectionCount; i++) {
                let shapeA, shapeB;
                do {
                    shapeA = shapes[Math.floor(Math.random() * shapes.length)];
                    shapeB = shapes[Math.floor(Math.random() * shapes.length)];
                } while (
                    shapeA === shapeB ||
                    usedPairs.has(`${shapeA.x}-${shapeA.y}-${shapeB.x}-${shapeB.y}`) ||
                    usedPairs.has(`${shapeB.x}-${shapeB.y}-${shapeA.x}-${shapeA.y}`)
                    );

                usedPairs.add(`${shapeA.x}-${shapeA.y}-${shapeB.x}-${shapeB.y}`);

                const type = Math.random(); // Naključno določimo vrsto črte
                ctx.beginPath();

                if (type < 0.33) {
                    // Ravna črta
                    ctx.moveTo(shapeA.x, shapeA.y);
                    ctx.lineTo(shapeB.x, shapeB.y);
                } else if (type < 0.66) {
                    // Ukrivljena črta
                    const controlX = (shapeA.x + shapeB.x) / 2 + Math.random() * 50 - 25;
                    const controlY = (shapeA.y + shapeB.y) / 2 + Math.random() * 50 - 25;
                    ctx.moveTo(shapeA.x, shapeA.y);
                    ctx.quadraticCurveTo(controlX, controlY, shapeB.x, shapeB.y);
                } else {
                    // Valovita črta

                    // Naključni parametri
                    const amplitude = Math.random() * 100 + 50; // Amplituda med 50 in 150
                    const frequency = Math.random() * 0.01 + 0.01; // Frekvenca med 0.01 in 0.02
                    const phaseShift = Math.random() * Math.PI * 2; // Naključna fazna premestitev

                    // const amplitude = Math.random() * 30 + 10; // Amplituda med 10 in 30
                    // const frequency = Math.random() * 0.1 + 0.1; // Frekvenca med 0.1 in 0.2
                    // const phaseShift = Math.random() * Math.PI * 2; // Naključna fazna premestitev

                    const segments = 23;
                    let prevX = shapeA.x;
                    let prevY = shapeA.y;

                    for (let j = 1; j <= segments; j++) {

                        // Naključni parametri
                        // const amplitude = Math.random() * 30 + 15; // Amplituda med 15 in 30
                        // const frequency = Math.random() * 0.1 + 0.1; // Frekvenca med 0.1 in 0.2
                        // const phaseShift = Math.random() * Math.PI * 2; // Naključna fazna premestitev


                        const t = j / segments;
                        const x = shapeA.x + (shapeB.x - shapeA.x) * t;

                        // const y = shapeA.y + (shapeB.y - shapeA.y) * t + Math.sin(j * Math.PI) * 20;
                        const y = shapeA.y + amplitude * Math.sin(frequency * x + phaseShift);

                        ctx.lineTo(x, y);
                        prevX = x;
                        prevY = y;
                    }
                }

                ctx.stroke();
            }
        };

        drawShapes();
        drawConnections();
    };

    const navigate = useNavigate();

    const saveImage = () => {
        const canvas = canvasRef.current;
        if (canvas) {
            const dataUrl = canvas.toDataURL("image/jpg");
            const a = document.createElement("a");
            a.href = dataUrl;
            a.download = "miro-Rogoznica.jpg";
            a.click();
        }
    };

    return (
        <div style={{textAlign: "center"}}>
            <h1>Joan Miró</h1>
            <canvas
                ref={canvasRef}
                width={canvasSize}
                height={canvasSize}
                style={{border: "1px solid black"}}
            />
            <Stack spacing={2} direction="row" sx={{mt: "20px"}} justifyContent="center">
                <Button variant="outlined" onClick={generateImage} sx={{mr: 2}}>
                    Naslikaj
                </Button>
                <Button variant="outlined" onClick={saveImage} sx={{mr: 2}}>
                    Shrani sliko
                </Button>
                <Button variant="outlined" onClick={()=>{navigate("/")}} sx={{mr: 2}}>
                    Nazaj
                </Button>
            </Stack>
        </div>
    );
};

export default MiroCanvas;
