import React from "react";
import { Box, Typography } from "@mui/material";

const Header: React.FC = () => {
    return (
        <Box textAlign="center" sx={{ marginBottom: 4 }}>

            <Typography variant="h4">
                Generator abstraktne umetnosti
            </Typography>

            <img src="/logo512.png" alt="Logo" style={{ width: 100, height: 100 }} />

            <Typography variant="body1" component="h1" gutterBottom>
                V likovni sekciji KD Rogoznica smo se odločili, da se spustimo v globoke vode abstraktne umetnosti. Da bi svoja umetniška obzorja še bolj širili, smo povabili tri legende tega področja, da nam osvetlijo pot. Na naši ustvarjalni zabavi so se tako pojavili trije velikani: Mondrian, Kandinsky in Miro. Ja, prav ste slišali – ti trije so sedaj z nami in pripravljeni, da vso svojo genialnost delijo z nami. Njihova umetnost je zdaj na voljo v neomejenih količinah, brez meja in brez omejitev. Torej, izberite mojstra, kliknite na njegovo podobo in se potopite v barve, linije in oblike, ki jih je za nas pripravil celoten abstraktni svet!
            </Typography>
        </Box>
    );
};

export default Header;