import React, {useEffect, useRef, useState} from 'react';
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";

interface CanvasProps {
    canvasSize: number;
}

const KandinskyCanvas: React.FC<CanvasProps> = ({canvasSize}) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [image, setImage] = useState<string | null>(null);  // Držimo stanje za sliko

    // Funkcija za risanje na canvas
    const drawCanvas = (ctx: CanvasRenderingContext2D) => {
        const width = ctx.canvas.width;
        const height = ctx.canvas.height;

        // Nastavitve
        ctx.clearRect(0, 0, width, height); // Počisti platno

        // Dodaj ozadje
        const background = new Image();
        const imgIdx = Math.ceil(Math.random() * 12)
        background.src = `/bck-${imgIdx}.png`;
        ctx.drawImage(background,0,0)

        // Naključni krogi
        for (let i = 0; i < 3; i++) {
            const x = Math.random() * width;
            const y = Math.random() * height;
            const radius = Math.random() * 100 + 30;
            const color = `hsl(${Math.random() * 360}, 70%, 50%)`;

            ctx.beginPath();
            ctx.arc(x, y, radius, 0, Math.PI * 2);
            ctx.fillStyle = color;
            ctx.fill();
        }

        // Naključne elipse
        for (let i = 0; i < 2; i++) {
            const x = Math.random() * width;
            const y = Math.random() * height;
            const radiusX = Math.random() * 100 + 30;
            const radiusY = Math.random() * 50 + 20;
            const color = `hsl(${Math.random() * 360}, 70%, 50%)`;

            ctx.beginPath();
            ctx.ellipse(x, y, radiusX, radiusY, 0, 0, Math.PI * 2);
            ctx.fillStyle = color;
            ctx.fill();
        }

        // Naključne trikotne oblike (polygoni)
        for (let i = 0; i < 2; i++) {
            const x = Math.random() * width;
            const y = Math.random() * height;
            const size = Math.random() * 100 + 50;
            const color = `hsl(${Math.random() * 360}, 70%, 50%)`;

            ctx.beginPath();
            ctx.moveTo(x, y);
            ctx.lineTo(x + size, y);
            ctx.lineTo(x + size / 2, y + size);
            ctx.closePath();
            ctx.fillStyle = color;
            ctx.fill();
        }

        // Naključni mnogokotniki
        for (let i = 0; i < 2; i++) {
            const sides = Math.floor(Math.random() * 5) + 5; // Naključna število strani (5 do 10)
            const x = Math.random() * width;
            const y = Math.random() * height;
            const radius = Math.random() * 80 + 30;
            const color = `hsl(${Math.random() * 360}, 70%, 50%)`;

            ctx.beginPath();
            for (let j = 0; j < sides; j++) {
                const angle = (j * 2 * Math.PI) / sides;
                const xPos = x + radius * Math.cos(angle);
                const yPos = y + radius * Math.sin(angle);
                ctx.lineTo(xPos, yPos);
            }
            ctx.closePath();
            ctx.fillStyle = color;
            ctx.fill();
        }

        // Naključne črte
        for (let i = 0; i < 4; i++) {
            const x1 = Math.random() * width;
            const y1 = Math.random() * height;
            const x2 = Math.random() * width;
            const y2 = Math.random() * height;
            const lineWidth = Math.random() * 10 + 2;
            const color = `hsl(${Math.random() * 360}, 70%, 50%)`;

            ctx.beginPath();
            ctx.moveTo(x1, y1);
            ctx.lineTo(x2, y2);
            ctx.lineWidth = lineWidth;
            ctx.strokeStyle = color;
            ctx.stroke();
        }

        // Shrani generirano sliko
        const imageData = ctx.canvas.toDataURL("image/jpeg");
        setImage(imageData);  // Shranimo sliko v stanje
    };

    const generateImage = () => {
        const canvas = canvasRef.current;
        if (canvas) {
            const ctx = canvas.getContext('2d');
            if (ctx) {
                canvas.width = canvasSize;
                canvas.height = canvasSize;
                drawCanvas(ctx);
            }
        }
    };

    const navigate = useNavigate();

    const saveImage = () => {
        if (image) {
            const link = document.createElement('a');
            link.href = image;
            link.download = 'kandinsky-image.jpg';
            link.click();
        }
    };

    useEffect(() => {
        generateImage(); // Ustvarimo začetno sliko ob nalaganju komponent
    }, []);

    return (
        <div style={{textAlign: 'center'}}>
            <h1>Wassily Kandinsky</h1>
            <canvas
                ref={canvasRef}
                style={{border: '1px solid black', display: 'block', margin: '0 auto'}}
            />

            <Stack spacing={2} direction="row" sx={{mt: "20px"}} justifyContent="center">
                <Button variant="outlined" onClick={generateImage} sx={{mr: 2}}>
                    Naslikaj
                </Button>
                <Button variant="outlined" onClick={saveImage} sx={{mr: 2}}>
                    Shrani sliko
                </Button>
                <Button variant="outlined" onClick={()=>{navigate("/")}} sx={{mr: 2}}>
                    Nazaj
                </Button>
            </Stack>
        </div>
    );
};

export default KandinskyCanvas;
