import React from "react";
import { Grid, Box } from "@mui/material";
import { Link } from "react-router-dom";

const images = [
    { id: 1, src: "/mondrian.jpg", alt: "Mondrian", link: "/mondrian" },
    { id: 2, src: "/miro.jpg", alt: "Miro", link: "/miro" },
    { id: 3, src: "/kandinsky.jpg", alt: "Kandinsky", link: "/kandinsky" },
];

const ImageGrid: React.FC = () => {
    return (
        <Grid container spacing={2} justifyContent="center">
            {images.map((image) => (
                <Grid item xs={12} sm={6} md={4} key={image.id}>
                    <Link to={image.link} style={{ textDecoration: "none" }}>
                        <Box
                            component="img"
                            src={image.src}
                            alt={image.alt}
                            sx={{
                                width: "100%",
                                height: "auto",
                                cursor: "pointer",
                                borderRadius: 2,
                                boxShadow: 3,
                            }}
                        />
                    </Link>
                </Grid>
            ))}
        </Grid>
    );
};

export default ImageGrid;